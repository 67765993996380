import './hero-slider.scss';

class HeroSlider {
    constructor($element) {
        this.$heroSliderRoot = $element;
        this.slider = [];

        setInterval(() => {
            [...this.$heroSliderRoot.querySelectorAll('.marquee *')].forEach(
                (el) => {
                    el.setAttribute('tabindex', '-1');
                    el.setAttribute('aria-hidden', 'true');
                }
            );
        }, 500);
    }
}

export { HeroSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $heroSliders = $context.querySelectorAll(
            '[data-hero-slider="root"]'
        );
        if ($heroSliders.length > 0) {
            for (let i = 0; i < $heroSliders.length; i++) {
                new HeroSlider($heroSliders[i]);
            }
        }
    }
});
